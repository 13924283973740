
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }

  img {
    height: 500px
  }
  
  @media (max-width: 768px) {
    img {
        height: 150px;
    }

}