
.hero {
  background-image: linear-gradient(to bottom, rgba(140, 141, 147, 0.2), #363636), url('../images/herobg.jpg');
  background-size: cover;
  background-color: #424242;
  color: white;
  text-align: center;
  padding: 100px 20px;
  transition: padding 0.3s ease;
}

.hero.collapsed {
  padding: 50px 20px;  /* Collapsed version with less padding */
}

.hero h1 {
  font-size: 3rem;
}

.hero p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.hero img {
  height: 200px;
  margin-top: 8px;
  margin-left: 20px; 
}


@media (max-width: 768px) {
    .hero {
        padding: 30px 10px;
        transition: padding 0.3s ease;
    }

    .hero.collapsed {
      padding: 10px 5px;  /* Collapsed version with less padding */
    }

   .hero img {
        height: 80px;
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 10px;
    }


}